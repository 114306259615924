<template>
  <div class="about-us">
    <h1>公司简介</h1>
    <div>
      <!-- <p>
        深圳市云端高科信息科技有限公司（简称“云端高科”）专业致力于气象、环境、农业、海洋、交通、能源等领域的探测系统建设和运行，是一家采用物联网技术提供全面信息化服务的科技创新公司，我们致力于开创成为信息化时代的专业数据提供商。凭借与业内国际一流设备厂家的紧密合作以及我们积累多年的技术、售后服务及软件开发的优势，为用户定制专业的多元化整体解决方案。
      </p> -->

      <!-- <p>
        深圳市云端高科信息科技有限公司
      </p>
      <p>
        成立于2011年
      </p>
      <p>
        深耕气象行业十年
      </p>
      <p>
        专注于珠三角地区气象、环保、海洋产业革命
      </p>
      <p>
        自成立来坚守气象、环境、农业、海洋、交通、能源等领域的探测系统建设和运行
      </p>
      <p>结合物联网技术</p>
      <p>提供全面信息化服务 致力于成为划时代的信息化时代的专业数据提供商</p>
      <p>
        紧密合作业内国际一流设备厂家、积累多年的技术、售后服务及软件开发的多重优势
      </p>
      <p>为用户定制专业的多元化整体解决方案</p>
      <p>坚持利用气象大数据和人工智能技术</p>
      <p>帮助用户提高收益，降低风险</p> -->

      <p>
        深圳市云端高科信息科技有限公司（简称“云端高科”）是一家致力于为气象、环境、生态、海洋、交通、能源、农业等领域客户提供监测设备、售后技术支持、数据质控、数据分析及应用等整体解决方案的国家高新技术企业，拥有30项专利及软件著作权。
      </p>
      <p>
        公司现有40人，核心管理层具备丰富的管理经验及气象、环境、海洋、软件开发及物联网行业资深背景；20余名技术骨干，毕业于国内外专业院校，90%为本科及以上学历，其中博士1名、硕士5名。技术及服务团队拥有多年气象、环境、通讯、雷达、电子、IT、物联网等领域的开发及服务经验，具备应对复杂项目的能力。
      </p>
      <p>
        企业总部位于深圳南山科技园，在北京、上海、重庆、大连设有分公司和代表处。凭借与业内国际一流设备厂家如芬兰维萨拉、荷兰Kipp&zonen、美国康贝尔、美国热电、英国Gill、美国Licor、美国Picarro、法国雷奥飞、新西兰艾瑞克、美国Radiometrics、航天新气象、武汉天虹、上海宝英的紧密合作、以及我们积累多年的技术支持、售后服务、数据应用&挖掘、软件开发及集成的优势，为用户量身定制专业、高效的多元化整体解决方案。
      </p>
      <p>
        客户包括深圳观象台、深圳环境监测站、深圳海洋预警预报中心、深圳机场、无锡环保局、无锡环境监测中心、重庆环境监测中心、广东联通、南方科技大学、深圳大学等数十家事业单位及企业。同时，与清华大学、北京大学、中国海洋大学、南信大、中山大学、暨南大学等院校有着紧密的合作。
      </p>
      <p>
        我们提供设备销售、技术和数据应用支持的领域涵盖：自动气象站、微波辐射计、通量辐射计、太阳辐射计、风廓线、闪电定位、风激光雷达、空气监测站、VOC监测站、臭氧监测仪、温室气体监测、浪高、浪向、潮位监测站等。我们不仅为以上提及的设备提供销售、安装调试、校准维护、专业培训等服务；同时也为客户提供质控、数据分析、数据融合应用方案及服务，按月、年等定期分析设备数据的可用性，结合业务分析相关监测现象产生的原因及相应的规律，为客户提供更有价值的业务报告。并及时针对异常现象或热点事件，快速响应为客户提供设备所捕捉到相关数据的分析报告，提供更高效及有力的数据应用支持。
      </p>
      <p>
        除了硬件及数据分析外，我们也为客户提供软件及集成整体解决方案。包括：
      </p>
      <p>
        1、数据采集、质控、预警的整体解决方案；
      </p>
      <p>
        2、能满足海量、高并发需要的设备运维及管理软件平台；
      </p>
      <p>
        3、数据分析、统计、实验室等业务用途软件平台开发；
      </p>
      <p>
        4、视频和数据结合业务观测平台，实现数值报警、视频联动；
      </p>
      <p>
        5、其它定制类软件开发。
      </p>
      <p>
        “专注、创新、分享”是我们的理念，也将是我们一直追求的目标！
      </p>
    </div>
    <h2>办公地址</h2>
    <div class="office-address">
      <div>深圳市南山区松坪山清华信息港科研楼713 丨 0755-23018957 丨 Cloudtech@szcloudtech.cn</div>
    </div>
    <h2>
      加入我们
    </h2>
    <a href="https://jobs.51job.com/all/co4834915.html" class="join-us">
      <img src="@/assets/image/about-us.png" alt="关于我们" />
      <div>
        <div>加入我们</div>
        <div>www.szcloudtech.cn</div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
.about-us {
  width: $effectiveArea;
  margin: 0 auto;
  font-size: 16px;
  padding-bottom: 55px;
  // text-align: center;
  h1,
  h2 {
    font-size: 36px;
    font-weight: bold;
    margin: 40px;
    text-align: center;
  }
  p {
    text-indent: 2em;
    line-height: 30px;
  }
  .office-address {
    text-align: center;
  }
  .join-us {
    width: 100%;
    height: 358px;
    font-size: 24px;
    color: #fff;
    position: relative;
    display: block;
    > div {
      position: absolute;
      top: 50px;
      left: 64px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
